import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

const scrollToContact = () => {
  scrollToID("contact-us");
}

const scrollToAbout = () => {
  scrollToID("about-us");
}

const scrollToWork = () => {
  scrollToID("our-work");
}

const scrollToID = id => {
  const element = document.getElementById(id);
  element.scrollIntoView({behavior: 'smooth'});
}

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="#Contact-Us" onClick={scrollToContact}>Contact</Link>
        </li>
        <li>
          <Link to="#About-Us" onClick={scrollToAbout}>Services</Link>
        </li>
        <li>
          <Link to="#Our-Work" onClick={scrollToWork}>Our Work</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;