import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split
  );  

  return (
    <section
      {...props}
      className={outerClasses}
      id="contact-us"
    >
      <div className="container">
        <div
          className={innerClasses}
        >
          <h2 className="m-0">
            Contact Us
          </h2>
          <p className="m-0 text-sm text-white">
            Let's work together.
          </p>

          <form name="contact" method="post" className="mt-16">
            <input type="hidden" name="form-name" value="contact" />
            <Input name="firstName" type="text" label="First Name" labelHidden placeholder="First Name" />
            <Input name="lastName" type="text" label="Last Name" labelHidden placeholder="Last Name" className="mt-12" />
            <Input name="email" type="email" label="Email" labelHidden placeholder="Email Address" className="mt-12" />
            <Input name="message" type="textarea" label="Message" labelHidden placeholder="Message..." className="mt-12" />
            <button type="submit" className="button button-dark button-wide-mobile mt-12">Send Message</button>
          </form>

        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;